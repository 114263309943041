<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1400"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins h-100">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Create Order SKU</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0">
          <input-group label="">
            <div class="h-75px col-12">
              <v-autocomplete
                v-model="formData.sku_id"
                label="SKU"
                item-text="systemCode"
                item-value="skuId"
                clearable
                outlined
                :items="items"
                :loading="isLoading"
                :search-input.sync="search"
                hide-no-data
                hide-selected
                dense
              ></v-autocomplete>
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model.number="formData.uom_type_id"
                label="UOM"
                :items="serverData.uoms"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.quantity"
                label="Quantity"
                clearable
                outlined
                dense
                type="Number"
                :min="1"
                @change="() => validateMinValue('quantity', 1, 'formData')"
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.line_no"
                label="Line number"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.batch_no"
                label="Batch number"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.serial_no"
                label="Serial number"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.lot_no"
                label="Lot number"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.inbound_asn_no"
                label="Inbound asn number"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.po_no"
                label="PO number"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.roll_no"
                label="Roll number"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.bl_no"
                label="BL number"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.mfg_date"
                    label="Manufacture date"
                    append-icon="mdi-calendar"
                    readonly
                    clearable
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="formData.mfg_date" />
              </v-menu>
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.mfg_year"
                label="Manufacture year"
                append-icon="mdi-calendar"
                clearable
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.exp_date"
                    label="Expiry date"
                    append-icon="mdi-calendar"
                    readonly
                    clearable
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="formData.exp_date" />
              </v-menu>
            </div>
          </input-group>
          <input-group label="">
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.net_weight"
                label="Net weight"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('net_weight', 0, 'formData')"
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.gross_weight"
                label="Gross weight"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('gross_weight', 0, 'formData')"
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model.number="formData.weight_uom_type_id"
                label="Weight UOM"
                :items="serverData.uoms"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.volume"
                label="Volume"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('volume', 0, 'formData')"
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model.number="formData.volume_uom_type_id"
                label="Volume UOM"
                :items="serverData.uoms"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.hs_code"
                label="HS code"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.unit_rate"
                label="Unit rate"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('unit_rate', 0, 'formData')"
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model="formData.unit_rate_currency_id"
                label="Unit rate currency"
                :items="serverData.currencies"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.import_entry_no"
                label="Import entry number"
                clearable
                outlined
                dense
              />
            </div>
          </input-group>
          <input-group label="">
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.vin_no"
                label="VIN number"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.engine_no"
                label="Engine number"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.body_model"
                label="Body model"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.specification"
                label="Specification"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.drive"
                label="Drive"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.condition"
                label="Condition"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.key_no"
                label="Key number"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.engine_capacity"
                label="Engine engine_capacity"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="
                  () => validateMinValue('engine_capacity', 0, 'formData')
                "
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.grade"
                label="Grade"
                clearable
                outlined
                dense
              />
            </div>
          </input-group>
          <input-group label="">
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.carton_number"
                label="Carton number"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model="formData.carton_uom_type_id"
                label="Carton UOM"
                :items="serverData.uoms"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.carton_length"
                label="Carton length"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('carton_length', 0, 'formData')"
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.carton_width"
                label="Carton width"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('carton_width', 0, 'formData')"
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.carton_height"
                label="Carton height"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('carton_height', 0, 'formData')"
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model="formData.carton_dim_uom_type_id"
                label="Carton dimension UOM"
                :items="serverData.uoms"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.carton_volume"
                label="Carton volume"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('carton_volume', 0, 'formData')"
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model="formData.carton_vol_uom_type_id"
                label="Carton volume UOM"
                :items="serverData.uoms"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model="formData.allocate_damage"
                label="Allocate damage"
                :items="booleanValues"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model="formData.zone_group_id"
                label="Zone group"
                :items="serverData.zone_groups"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model="formData.zone_id"
                label="Zone"
                :items="serverData.zones"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model="formData.location_id"
                label="Location"
                :items="serverData.locations"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model="formData.coo_id"
                label="Country of origin"
                :items="serverData.countries"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12">
              <v-text-field
                v-model="formData.carton_description"
                label="Carton Description"
                clearable
                outlined
                dense
              />
            </div>
          </input-group>
        </form>

        <div
          class="modal-footer border-0 py-8 d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import fieldValueValidation from "@/own/mixins/fieldValueValidation";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import InputGroup from "@/own/components/InputGroup.vue";

export default {
  mixins: [fieldValueValidation],
  components: { InputGroup },
  name: "CreateSKUDetails",
  props: ["getDataFromApi", "orderId"],
  data: () => ({
    dialog: false,
    serverData: null,
    menu: false,
    menu2: false,
    menu3: false,
    isLoading: false,
    search: null,
    tab: 0,
    entries: [],
    doneTypingInterval: 1500,
    typingTimer: null,
    formData: {
      id: null, //ok
      quantity: null, //ok
      carton_number: null, //ok
      carton_uom_type_id: null, //ok
      carton_description: null, //ok
      carton_length: null, //ok
      carton_width: null, //ok
      carton_height: null, //ok
      carton_dim_uom_type_id: null, //new
      carton_volume: null, //ok
      carton_vol_uom_type_id: null, //new
      batch_no: null, //ok
      serial_no: null, //ok
      lot_no: null, //ok
      mfg_date: null, //ok
      weight_uom_type_id: null, //ok
      net_weight: null, //ok
      gross_weight: null, //ok
      volume_uom_type_id: null, //ok
      volume: null, //ok
      coo_id: null, //ok
      zone_group_id: null, //ok
      zone_id: null, //ok
      location_id: null, //ok
      allocate_damage: null, //ok
      unit_rate: null, //ok
      unit_rate_currency_id: null, //ok
      import_entry_no: null, //ok
      inbound_asn_no: null, //ok
      po_no: null, //ok
      roll_no: null, //ok
      bl_no: null, //ok
      hs_code: null, //ok
      grade: null, //ok
      vin_no: null, //ok
      body_model: null, //ok
      specification: null, //ok
      drive: null, //ok
      condition: null, //ok
      key_no: null, //ok
      engine_no: null, //ok
      engine_capacity: null, //ok
      mfg_year: null, //ok
      exp_date: null, //ok
      line_no: null, //ok
      sku_id: null, //new
      uom_type_id: null, //new
    },
  }),
  methods: {
    async getData(value) {
      await ApiService.post("/skus/search", {
        q: value,
        client: this.serverData?.customer_id,
      })
        .then((response) => {
          this.entries = response.data.skus;
          this.isLoading = false;
        })
        .catch(() => {
          this.entries = [];
          this.isLoading = false;
        });
    },
    loadDataFromServer() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { id: this.orderId };
      ApiService.post(`/fulfillment/orders/create-order-sku`, data)
        .then((response) => {
          this.serverData = response.data;
          this.resetForm();
          this.$store.commit(SET_PAGE_LOADING, false);
          // this.updateValidationStateOfSteps();
        })
        .catch(() => {
          this.dialog = false;
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    toggleModal(item_id) {
      if (this.dialog) {
        this.resetForm();
      } else {
        this.loadDataFromServer(item_id);
      }
      this.dialog = !this.dialog;
    },
    updateValidationStateOfSteps() {
      this.$v.$touch();
      this.categories.forEach((cat) => {
        cat.valid = !cat.rules(this);
      });
    },
    resetValidationStateOfSteps() {
      this.categories.forEach((cat) => {
        cat.valid = true;
      });
    },

    submitForm() {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      this.$store.commit(SET_PAGE_LOADING, true);
      // let data = JSON.stringify(this.entCreate);
      this.formData.id = this.orderId;
      ApiService.post("/fulfillment/orders/store-order-sku", {
        ...this.formData,
      })
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Record has been successfully created!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.getDataFromApi();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      // }
    },
    resetForm() {
      this.menu = false;
      this.menu2 = false;
      this.isLoading = false;
      this.tab = 0;
      this.entries = [];
      this.typingTimer = null;
      this.formData = {};
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    isStepperReady() {
      try {
        return this.$v ? true : false;
      } catch (error) {
        return false;
      }
    },
    items: function () {
      return this.entries;
    },
    categories() {
      return [
        {
          name: "Step 1",
          valid: true,
          rules: (el) => {
            try {
              return el[`$v`][`formData`].$invalid;
            } catch {
              return true;
            }
          },
        },
        {
          name: "Step 2",
          valid: true,
          rules: (el) => {
            try {
              return el[`$v`][`formData`].$invalid;
            } catch {
              return true;
            }
          },
        },
        {
          name: "Step 3",
          valid: true,
          rules: (el) => {
            try {
              return el[`$v`][`formData`].$invalid;
            } catch {
              return true;
            }
          },
        },
        {
          name: "Step 4",
          valid: true,
          rules: (el) => {
            try {
              return el[`$v`][`formData`].$invalid;
            } catch {
              return true;
            }
          },
        },
      ];
    },
    booleanValues: function () {
      return [
        { index: 0, text: "No" },
        { index: 1, text: "Yes" },
      ];
    },
    // nameErrors: function() {
    //   return this.handleFormValidation("name", this);
    // },
  },
  watch: {
    search(val) {
      clearTimeout(this.typingTimer);
      if (!val || val == "") {
        return;
      }
      this.typingTimer = setTimeout(() => {
        this.isLoading = true;
        this.getData(val.trim());
      }, this.doneTypingInterval);
    },
  },
};
</script>

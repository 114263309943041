<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    fullscreen
  >
    <div class="bg-white poppins position-relative min-h-100">
      <div
        class="modal-header py-3 align-center bg-white position-sticky top-0 left-0 zindex-5"
      >
        <h4 class="mb-0 font-weight-bolder poppins">ASN detail</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div v-for="(item, i) in other" :key="i">
          <pop-up-selector
            :item="item"
            :order_id="order_id"
            :refresher="refresherFunction"
            :toggleDetail="toggleModal"
          ></pop-up-selector>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import PopUpSelector from "@/own/components/fulfillment/asn/popup/PopUpSelector.vue";
export default {
  name: "Others",
  components: { PopUpSelector },
  data: () => ({
    dialog: false,
    other: null,
    order_id: null,
    refresherFunction: null,
  }),
  methods: {
    setData(otherData, order_id, refresherFunction) {
      this.other = otherData;
      this.order_id = order_id;
      this.refresherFunction = refresherFunction;
    },
    toggleModal() {
      if (this.dialog) {
        this.other = null;
        this.order_id = null;
        this.refresherFunction = null;
        this.dialog = !this.dialog;
      } else {
        this.dialog = !this.dialog;
      }
    },
  },
};
</script>

<style scoped>
div {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
}
</style>
